import { SxProps } from '@mui/material'

const paperContainer: SxProps = {
  border: 0,
  borderRadius: 0,
  boxShadow: 'none',
  width: '100%',
  p: 0,
  m: 0,
  mt: '12px',
}

const dataGrid: SxProps = {
  border: 0,
  '.MuiDataGrid-topContainer': {
    '.MuiDataGrid-columnHeaders': {
      '.MuiDataGrid-columnHeader': {
        padding: '5px',
        '.MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '.MuiDataGrid-columnHeaderTitle': {
          fontFamily: 'Source Sans Pro',
          fontSize: '14px',
          fontWeight: 700,
          color: '#7E8BA6',
        },
        '&:focus, &:focus-within': {
          outline: 'none',
        },
      },
    },
  },
  '.MuiDataGrid-virtualScrollerContent': {
    '.MuiDataGrid-virtualScrollerRenderZone': {
      '.MuiDataGrid-row': {
        '.MuiDataGrid-cell, .MuiDataGrid-cell > .MuiStack-root': {
          fontFamily: 'Source Sans Pro',
          fontSize: '16px',
          fontWeight: 400,
          color: '#1C2842',
          display: 'flex',
          alignItems: 'center',
          '&:focus,  &:focus-within': {
            outline: 'none',
          },
        },
        '.MuiDataGrid-cell': {
          padding: '10px 5px',
        },
      },
    },
  },
  '.MuiDataGrid-footerContainer': {
    '.MuiTablePagination-root': {
      '.MuiToolbar-root': {
        '.MuiTablePagination-displayedRows': {
          display: 'none',
        },
        'nav.MuiPagination-root': {
          'ul.MuiPagination-ul': {
            li: {
              'button.MuiPaginationItem-root': {
                borderRadius: '8px',
                width: 'auto',
                height: '32px',
                cursor: 'pointer',
                fontFamily: 'Source Sans Pro',
                fontSize: '16px',
                fontWeight: 400,
                color: '#1C2842',
                '&.Mui-selected': {
                  backgroundColor: '#7E8BA6',
                  color: '#fff',
                },
                '&.MuiPaginationItem-previousNext': {
                  width: 'auto',
                },
              },
              '&:first-child': {
                'button.MuiPaginationItem-root': {
                  mr: '10px',
                },
              },
              '&:last-child': {
                'button.MuiPaginationItem-root': {
                  ml: '10px',
                },
              },
            },
          },
        },
      },
    },
  },
  '.MuiDataGrid-filler': {
    display: 'none',
  },
}

const styles = { paperContainer, dataGrid }

export default styles
