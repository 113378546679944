// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Overview__locationsContainer--E55eQ{background-color:#fff;padding:20px 24px;border-radius:15px 15px 0 0}.Overview__locationsContainer--E55eQ h3{font-family:\"Dosis\";font-size:20px;font-weight:600;line-height:32px;text-align:left;color:#1c2842}", "",{"version":3,"sources":["webpack://./app/containers/VerticalPage/ecommerce-extended/Overview/Overview.scss"],"names":[],"mappings":"AAAA,qCACE,qBAAA,CACA,iBAAA,CACA,2BAAA,CAEA,wCACE,mBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,eAAA,CACA,aAAA","sourcesContent":[".locationsContainer {\n  background-color: white;\n  padding: 20px 24px;\n  border-radius: 15px 15px 0 0;\n\n  h3 {\n    font-family: 'Dosis';\n    font-size: 20px;\n    font-weight: 600;\n    line-height: 32px;\n    text-align: left;\n    color: #1c2842;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"locationsContainer": "Overview__locationsContainer--E55eQ"
};
export default ___CSS_LOADER_EXPORT___;
