export interface IExperience {
  name: string
  jobLocation: string
  jobRole: string
  jobDepartment: string
  dateFrom: string
  dateTo: string
  jobDesc: string
  currentJob: boolean
  companyUrl: string
  isExecutiveRole: boolean
  canonicalUrl: string
  status: string
}

export interface IEmployee {
  experiences?: IExperience[]
  name: string
  jobLocation: string
  jobRole: string
  jobDepartment: string
  dateFrom: string
  dateTo: string
  jobDesc: string
  currentJob: boolean
  companyUrl: string
  isExecutiveRole: boolean
  status: string
  canonicalUrl: string
}

export const formatDate = (timestamp: number) => {
  const date = new Date(timestamp)
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const month = monthNames[date.getMonth()]
  const year = date.getFullYear()
  return `${month} ${year}`
}

export const calculatePeriod = (startTimestamp: number, endTimestamp: number) => {
  const startDate = new Date(startTimestamp)
  const endDate = new Date(endTimestamp)

  const diffMilliseconds = endDate.getTime() - startDate.getTime()
  const diffMonths = diffMilliseconds / (1000 * 60 * 60 * 24 * 30.44)

  const years = Math.floor(diffMonths / 12)
  const months = Math.floor(diffMonths % 12)

  let period = ''
  if (years > 0) {
    period += `${years} yr `
  }
  if (months > 0) {
    period += `${months} mos`
  }

  return period.trim()
}
