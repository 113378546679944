import React, { useState } from 'react'
import Box from '@mui/material/Box'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import GaugeComponent from 'react-gauge-component'
import styles from './ScoreCard.styles.scss'

interface Parameter {
  parameter: string
  html: React.ReactNode
}

interface ScoreCardProps {
  title: 'Magnitude' | 'Risk'
  parameters: Parameter[]
  value: number
  escalationLabel?: string
}

const ScoreCard = ({ title, parameters, value, escalationLabel }: ScoreCardProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleParameters = () => {
    setIsOpen(!isOpen)
  }

  const getColorByValue = (value: number) => {
    if (value < 25) return '#00C853'
    if (value < 50) return '#FFEB3B'
    if (value < 75) return '#FF9800'
    return '#FF3B30'
  }

  const subArcs =
    title === 'Risk'
      ? [
          { limit: value, color: getColorByValue(value) },
          { limit: 100, color: '#DADBDF' },
        ]
      : [
          { limit: value, color: '#2257C5' },
          { limit: 100, color: '#DADBDF' },
        ]

  return (
    <Box className={styles.box}>
      <h3>{title}</h3>
      <Box sx={{ position: 'relative', width: 'fit-content', margin: '0 auto', height: '155px' }}>
        <GaugeComponent
          type='semicircle'
          value={value}
          minValue={0}
          maxValue={100}
          arc={{
            subArcs,
            width: 0.1,
          }}
          pointer={{
            hide: true,
          }}
          labels={{
            valueLabel: {
              hide: true,
            },
            tickLabels: {
              hideMinMax: true,
            },
          }}
        />
        <div
          style={{
            position: 'absolute',
            bottom: '10px',
            left: '50%',
            transform: 'translateX(-50%)',
            textAlign: 'center',
          }}
        >
          {value >= 90 && <div style={{ fontSize: '16px', color: '#7E8BA6', fontWeight: '400' }}>Critical</div>}
          <div style={{ fontSize: '40px', color: '#1C2842', fontWeight: '600' }}>{escalationLabel || value}</div>
        </div>
      </Box>
      <h4 onClick={toggleParameters} className={styles.parametersTitle}>
        {title} Parameters {isOpen ? <ExpandLessIcon fontSize='large' /> : <ExpandMoreIcon fontSize='large' />}
      </h4>
      {isOpen && (
        <ul className={styles.parameters}>
          {parameters.map((param, index) => (
            <li key={index}>
              {param.parameter}
              {param.html && <div>{param.html}</div>}
            </li>
          ))}
        </ul>
      )}
    </Box>
  )
}

export default ScoreCard
