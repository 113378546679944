import React from 'react'
import WhiteBoxWrapper from '../WhiteBoxWrapper/WhiteBoxWrapper'
import styles from './ContactInfo.scss'
import Box from '@mui/material/Box'
import SocialBlock from '../SocialBlock/SocialBlock'
import TabScrolledContent from '../TabScrolledContent/TabScrolledContent'

const FacebookUrl = require('../Assets/facebook.svg') as string
const InstagramUrl = require('../Assets/instagram.svg') as string
const PinterestUrl = require('../Assets/pinterest.svg') as string
const WebUrl = require('../Assets/web.svg') as string

const phonesUrl = require('../Assets/phones.svg') as string
const emailsUrl = require('../Assets/emails.svg') as string
const addressesUrl = require('../Assets/addresses.svg') as string

const ContactInfo = () => {
  const socialLinksData: { [key: string]: { logo: JSX.Element; backgroundColor: string; textColor: string } } = {
    website: {
      logo: <img src={WebUrl} />,
      backgroundColor: '#EDF1FF',
      textColor: '#2436BA',
    },
    facebook: {
      logo: <img src={FacebookUrl} />,
      backgroundColor: '#E2EBFF',
      textColor: '#1A56E5',
    },
    instagram: {
      logo: <img src={InstagramUrl} />,
      backgroundColor: '#FDEFF3',
      textColor: '#E1306C',
    },
    pinterest: {
      logo: <img src={PinterestUrl} />,
      backgroundColor: '#FEE9F2',
      textColor: '#E60023',
    },
  }

  const externalLinks: { [key: string]: string[] } = {
    website: ['mignonandmignon.com', 'mignonandmignon.com'],
    facebook: ['@MignonandMignon', '@MignonandMignon'],
    instagram: ['@MignonandMignon', '@MignonandMignon'],
    pinterest: ['@MignonandMignon', '@MignonandMignon'],
  }

  const socialLinks = Object.entries(externalLinks).flatMap(([key, links]) =>
    links.map(link => ({
      logo: socialLinksData[key].logo,
      text: link,
      backgroundColor: socialLinksData[key].backgroundColor,
      textColor: socialLinksData[key].textColor,
    })),
  )

  const phones = ['(123) 456-7890', '(098) 765-4321', '(555) 555-5555']

  const emails = ['info@mignonandmignon.com', 'support@mignonandmignon.com', 'sales@mignonandmignon.com']

  const addresses = [
    '123 Main St, Springfield, IL 62701',
    '456 Elm St, Shelbyville, IL 62565',
    '789 Oak St, Capital City, IL 62703',
  ]

  return (
    <TabScrolledContent>
      <WhiteBoxWrapper className={styles.contactInfoContainer} sx={{ p: 3 }}>
        <Box>
          <h2>WebLinks</h2>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '20px',
              borderBottom: '1px solid #EAECF0',
              paddingTop: 2,
              paddingBottom: 3,
            }}
          >
            {socialLinks.map((link, index) => (
              <SocialBlock
                key={index}
                logo={link.logo}
                text={link.text}
                backgroundColor={link.backgroundColor}
                textColor={link.textColor}
              />
            ))}
          </Box>
        </Box>
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
            <Box sx={{ width: '100%' }}>
              <h2 style={{ paddingBottom: 8 }}>Phones</h2>
              <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                {phones.map((phone, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <img src={phonesUrl} />
                    {phone}
                  </Box>
                ))}
              </Box>
            </Box>
            <Box sx={{ width: '100%' }}>
              <h2 style={{ paddingBottom: 8 }}>E-mails</h2>
              <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                {emails.map((email, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <img src={emailsUrl} />
                    {email}
                  </Box>
                ))}
              </Box>
            </Box>
            <Box sx={{ width: '100%' }}>
              <h2 style={{ paddingBottom: 8 }}>Addresses</h2>
              <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                {addresses.map((address, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <img src={addressesUrl} />
                    {address}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </WhiteBoxWrapper>
    </TabScrolledContent>
  )
}
export default ContactInfo
