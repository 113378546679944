import React from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/system/Box'
import Typography from '@mui/material/Typography'
import { DataGrid } from '@mui/x-data-grid'
import Paper from '@mui/material/Paper'
import WhiteBoxWrapper from '../WhiteBoxWrapper/WhiteBoxWrapper'
import CustomPagination from '../Pagination/Pagination'
import columns from './columns'
import { allBusinessNameTableHeader, addAddressesHeader } from './utils'
import styles from './styles'
import BusinessDataSources from 'components/BusinessDataSources/BusinessDataSources'
import TabScrolledContent from '../TabScrolledContent/TabScrolledContent'

const registerFoundIconUrl = require('./icons/registerFound.svg')
const noRegistryFoundUrl = require('./icons/noRegistryFound.svg')

const rows = [
  {
    id: Math.random(),
    businessName: 'Name Mary LLC',
    dba: 'Mary Store',
    address: '123 Main St, Springfield, IL',
    licenseId: 'C6663423',
    entityName: 'Mary Enterprises Inc',
    status: 'Active',
  },
  {
    id: Math.random(),
    businessName: 'Happy Solutions LLC',
    dba: 'Happy Store',
    address: '456 Oak St, Dallas, TX',
    licenseId: 'C6663423',
    entityName: 'Happy Solutions Inc',
    status: 'Inactive',
  },
  {
    id: Math.random(),
    businessName: 'Blue Sky LLC',
    dba: 'Sky Market',
    address: '789 Pine St, Miami, FL',
    licenseId: 'C6663423',
    entityName: 'Blue Sky Enterprises',
    status: 'Active',
  },
  {
    id: Math.random(),
    businessName: 'Tech Wave LLC',
    dba: 'Wave Electronics',
    address: '101 Maple St, San Diego, CA',
    licenseId: 'C6663423',
    entityName: 'Tech Wave Holdings',
    status: 'Active',
  },
  {
    id: Math.random(),
    businessName: 'Green Earth LLC',
    dba: 'Earth Naturals',
    address: '202 Cedar Ave, New York, NY',
    licenseId: 'C6663423',
    entityName: 'Green Earth Solutions',
    status: 'Inactive',
  },
  {
    id: Math.random(),
    businessName: 'Redstone LLC',
    dba: 'Redstone Bakery',
    address: '303 Birch Blvd, Seattle, WA',
    licenseId: 'C6663423',
    entityName: 'Redstone Enterprises',
    status: 'Active',
  },
  {
    id: Math.random(),
    businessName: 'Yellow Bird LLC',
    dba: 'Bird Coffee Shop',
    address: '404 Willow Ln, Chicago, IL',
    licenseId: 'C6663423',
    entityName: 'Yellow Bird Ventures',
    status: 'Inactive',
  },
  {
    id: Math.random(),
    businessName: 'Silver Lining LLC',
    dba: 'Lining Fashion',
    address: '505 Elm St, Boston, MA',
    licenseId: 'C6663423',
    entityName: 'Silver Lining Apparel',
    status: 'Active',
  },
  {
    id: Math.random(),
    businessName: 'Blue Horizon LLC',
    dba: 'Horizon Realty',
    address: '606 Oak Dr, Atlanta, GA',
    licenseId: 'C6663423',
    entityName: 'Blue Horizon Properties',
    status: 'Active',
  },
  {
    id: Math.random(),
    businessName: 'Sunshine LLC',
    dba: 'Sunshine Daycare',
    address: '707 Palm St, Phoenix, AZ',
    licenseId: 'C6663423',
    entityName: 'Sunshine Kids Inc',
    status: 'Inactive',
  },
  {
    id: Math.random(),
    businessName: 'Dream Ventures LLC',
    dba: 'Dream Café',
    address: '808 Beach Blvd, Los Angeles, CA',
    licenseId: 'C6663423',
    entityName: 'Dream Ventures Group',
    status: 'Active',
  },
  {
    id: Math.random(),
    businessName: 'Ocean Breeze LLC',
    dba: 'Breeze Rentals',
    address: '909 Lakeview Rd, Tampa, FL',
    licenseId: 'C6663423',
    entityName: 'Ocean Breeze Rentals Inc',
    status: 'Active',
  },
  {
    id: Math.random(),
    businessName: 'Golden Touch LLC',
    dba: 'Golden Spa',
    address: '111 River Rd, Nashville, TN',
    licenseId: 'C6663423',
    entityName: 'Golden Touch Services',
    status: 'Inactive',
  },
  {
    id: Math.random(),
    businessName: 'Moonlight LLC',
    dba: 'Moonlight Bar',
    address: '222 Mountain St, Denver, CO',
    licenseId: 'C6663423',
    entityName: 'Moonlight Entertainment',
    status: 'Active',
  },
  {
    id: Math.random(),
    businessName: 'Fresh Start LLC',
    dba: 'Fresh Bakery',
    address: '333 Forest Ave, Austin, TX',
    licenseId: 'C6663423',
    entityName: 'Fresh Start Foods',
    status: 'Inactive',
  },
  {
    id: Math.random(),
    businessName: 'Starlight LLC',
    dba: 'Starlight Diner',
    address: '444 Sunset Blvd, Las Vegas, NV',
    licenseId: 'C6663423',
    entityName: 'Starlight Ventures',
    status: 'Active',
  },
  {
    id: Math.random(),
    businessName: 'Silver Fox LLC',
    dba: 'Fox Clothing',
    address: '555 Main St, Charlotte, NC',
    licenseId: 'C6663423',
    entityName: 'Silver Fox Apparel',
    status: 'Inactive',
  },
  {
    id: Math.random(),
    businessName: 'Sunset LLC',
    dba: 'Sunset Grill',
    address: '666 Harbor Dr, San Francisco, CA',
    licenseId: 'C6663423',
    entityName: 'Sunset Enterprises',
    status: 'Active',
  },
  {
    id: Math.random(),
    businessName: 'Blue Ocean LLC',
    dba: 'Ocean Catering',
    address: '777 Ocean Ave, Honolulu, HI',
    licenseId: 'C6663423',
    entityName: 'Blue Ocean Foods',
    status: 'Active',
  },
  {
    id: Math.random(),
    businessName: 'Red Sun LLC',
    dba: 'Sun Bar & Grill',
    address: '888 Island Rd, Key West, FL',
    licenseId: 'C6663423',
    entityName: 'Red Sun Ventures',
    status: 'Inactive',
  },
]

const SellerRegistry = () => {
  return (
    <TabScrolledContent>
      {/* Please do not remove commented component, we will use it when No Registry Found */}
      {/* <WhiteBoxWrapper>
        <Stack flexDirection='row' alignItems='center' justifyContent='center' gap='8px'>
          <Box component='img' width={32} height={32} src={noRegistryFoundUrl} alt='No Register found' />
          <Typography sx={styles.title}>No Registry Found</Typography>
        </Stack>
      </WhiteBoxWrapper> */}
      <WhiteBoxWrapper sx={{ mt: '15px' }}>
        <Stack flexDirection='row' alignItems='center' gap='8px'>
          <Box component='img' width={32} height={32} src={registerFoundIconUrl} alt='Register found' />
          <Typography sx={styles.title}>Registries Found</Typography>
        </Stack>
        <Paper sx={styles.paperContainer}>
          <DataGrid
            disableColumnSorting
            disableColumnMenu
            disableColumnResize
            disableColumnSelector
            disableColumnFilter
            rows={rows}
            columns={columns}
            initialState={{ pagination: { paginationModel: { page: 0, pageSize: 5 } } }}
            sx={styles.dataGrid}
            slots={{
              pagination: CustomPagination,
            }}
          />
        </Paper>
      </WhiteBoxWrapper>
      <WhiteBoxWrapper sx={{ mt: '15px' }}>
        <Stack flexDirection='row' alignItems='center' gap='15px'>
          <Stack flex={1} sx={{ p: '0 8px 0 8px' }}>
            <Typography sx={styles.title}>ALL BUSINESS Names / DBA</Typography>
            <Stack flexDirection='row' alignItems='center' justifyContent='space-between' sx={{ mt: '12px' }}>
              {allBusinessNameTableHeader.map(name => (
                <Typography key={name} sx={styles.subTitle}>
                  {name}
                </Typography>
              ))}
            </Stack>
            <Stack flexDirection='row' alignItems='center' justifyContent='space-between' sx={{ mt: '12px' }}>
              <Stack sx={styles.tableCell}>Name Mary LLC</Stack>
              <Stack>
                <BusinessDataSources isClickable dataSources={['airbnb']} />
              </Stack>
            </Stack>
            <Stack flexDirection='row' alignItems='center' justifyContent='space-between' sx={{ mt: '12px' }}>
              <Stack sx={styles.tableCell}>Hola Amigo Inc.</Stack>
              <Stack>
                <BusinessDataSources isClickable dataSources={['airbnb']} />
              </Stack>
            </Stack>
            <Stack flexDirection='row' alignItems='center' justifyContent='space-between' sx={{ mt: '12px' }}>
              <Stack sx={styles.tableCell}>Mary Ant</Stack>
              <Stack>
                <BusinessDataSources isClickable dataSources={['airbnb']} />
              </Stack>
            </Stack>
          </Stack>
          <Stack flex={1} sx={{ p: '0 8px 0 8px' }}>
            <Typography sx={styles.title}>All Addresses</Typography>
            <Stack flexDirection='row' alignItems='center' justifyContent='space-between' sx={{ mt: '12px' }}>
              {addAddressesHeader.map(name => (
                <Typography key={name} sx={styles.subTitle}>
                  {name}
                </Typography>
              ))}
            </Stack>
            <Stack flexDirection='row' alignItems='center' justifyContent='space-between' sx={{ mt: '12px' }}>
              <Stack sx={styles.tableCell}>1st Street, Blacksheep, OK, USA</Stack>
              <Stack>
                <BusinessDataSources isClickable dataSources={['airbnb']} />
              </Stack>
            </Stack>
            <Stack flexDirection='row' alignItems='center' justifyContent='space-between' sx={{ mt: '12px' }}>
              <Stack sx={styles.tableCell}>Lonely House Ave., Pierre, SD, USA</Stack>
              <Stack>
                <BusinessDataSources isClickable dataSources={['airbnb']} />
              </Stack>
            </Stack>
            <Stack flexDirection='row' alignItems='center' justifyContent='space-between' sx={{ mt: '12px' }}>
              <Stack sx={styles.tableCell}>13th Street, Kingston, NY, USA</Stack>
              <Stack>
                <BusinessDataSources isClickable dataSources={['airbnb']} />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </WhiteBoxWrapper>
    </TabScrolledContent>
  )
}

export default SellerRegistry
