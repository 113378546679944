import React from 'react'
import { gridPageCountSelector, GridPagination, useGridApiContext, useGridSelector } from '@mui/x-data-grid'
import { TablePaginationProps } from '@mui/material/TablePagination'
import MuiPagination from '@mui/material/Pagination'
import PaginationItem from '@mui/material/PaginationItem'
import Stack from '@mui/material/Stack'
import ArrowLeftRoundedIcon from '@mui/icons-material/ArrowLeftRounded'
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded'

const PreviousButton = () => (
  <Stack direction='row' alignItems='center'>
    <ArrowLeftRoundedIcon sx={{ width: 28, height: 28 }} />
    Previous
  </Stack>
)

const NextButton = () => (
  <Stack direction='row' alignItems='center'>
    Next
    <ArrowRightRoundedIcon sx={{ width: 28, height: 28 }} />
  </Stack>
)

function Pagination({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
  const apiRef = useGridApiContext()
  const pageCount = useGridSelector(apiRef, gridPageCountSelector)

  return (
    <MuiPagination
      color='primary'
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1)
      }}
      renderItem={item => <PaginationItem slots={{ previous: PreviousButton, next: NextButton }} {...item} />}
    />
  )
}

function CustomPagination(props: any) {
  return <GridPagination ActionsComponent={Pagination} {...props} />
}

export default CustomPagination
