import { SxProps } from '@mui/material'
import commonStyles from '../commonStyles'

const title: SxProps = {
  fontFamily: 'Dosis',
  fontSize: '20px',
  fontWeight: 600,
  textTransform: 'uppercase',
}

const subTitle: SxProps = {
  fontFamily: 'Source Sans Pro',
  fontSize: '14px',
  fontWeight: 700,
  color: '#7E8BA6',
  textTransform: 'uppercase',
}

const tableCell: SxProps = {
  fontFamily: 'Source Sans Pro',
  fontSize: '16px',
  fontWeight: 400,
}

const styles = {
  title,
  paperContainer: commonStyles.paperContainer,
  dataGrid: commonStyles.dataGrid,
  subTitle,
  tableCell,
}

export default styles
