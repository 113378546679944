import React, { FC, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import Stack from '@mui/material/Stack'
import { tabsIds, getTabsList, overviewMapping } from './utils'
import Tabs from 'components/common/Tabs'
import GraphContainer from 'containers/VerticalPage/common/GraphContainer/GraphContainer'
import type { CurrentZone } from 'utils/types'
import { selectCurrentZone } from 'store/global'
import SellerRegistry from '../SellerRegistry/SellerRegistry'
import styles from 'containers/VerticalPage/common/styles.scss'
import sxStyles from './styles'
import Overview from '../Overview/Overview'
import Employees from '../Employees/Employees'
import ContactInfo from '../ContactInfo/ContactInfo'

const tabsList = getTabsList()

const Details: FC = () => {
  const currentZone: CurrentZone = useSelector(selectCurrentZone)

  const [activeTab, setActiveTab] = useState<number>(tabsIds.OVERVIEW)

  const handleTabClick = (event: React.SyntheticEvent<Element, Event>, value: number) => {
    setActiveTab(value)
  }

  const contentByTabMap: { [key: number]: JSX.Element } = useMemo(
    () => ({
      [tabsIds.OVERVIEW]: <Overview />,
      [tabsIds.SELLER_REGISTRY]: <SellerRegistry />,
      [tabsIds.EMPLOYEES]: <Employees />,
      [tabsIds.CONTACT_INFO]: <ContactInfo />,
    }),
    [],
  )

  return (
    <Stack sx={sxStyles.container}>
      <div className={styles.tabsWrapper}>
        <Tabs
          tabs={tabsList}
          value={activeTab}
          disabledIds={[tabsIds.SOLD_ITEMS, tabsIds.REVIEWS]}
          tabsClasses={{
            root: styles.root,
            flexContainer: styles.flexContainer,
          }}
          tabClasses={{
            root: styles.tabRoot,
            disabled: styles.tabDisabled,
          }}
          tabsSx={{
            p: '0 16px 0 16px',
          }}
          onChange={handleTabClick}
        />
        {currentZone?.ecommerceExtendedGraphIndices && (
          <GraphContainer zone={currentZone.ecommerceExtendedGraphIndices} overviewMapping={overviewMapping} />
        )}
      </div>
      <Stack sx={sxStyles.tabWrapper}>{contentByTabMap[activeTab]}</Stack>
    </Stack>
  )
}

export default Details
