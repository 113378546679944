import React, { ReactNode, FC } from 'react'
import Stack from '@mui/material/Stack'

interface ITabScrolledContent {
  children?: ReactNode
}

const TabScrolledContent: FC<ITabScrolledContent> = ({ children }) => <Stack sx={{ p: '16px' }}>{children}</Stack>

export default TabScrolledContent
