import { SxProps } from '@mui/material'

const text: SxProps = {
  fontFamily: 'Source Sans Pro',
  fontSize: '16px',
  fontWeight: 400,
}

const button: SxProps = {
  color: 'rgba(34, 87, 197, 1)',
  fontFamily: 'Source Sans Pro',
  fontSize: '16px',
  fontWeight: 400,
  p: 0,
  textTransform: 'none',
}

const styles = { text, button }

export default styles
