import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import Stack from '@mui/material/Stack'
import Box from '@mui/system/Box'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

const bagIconUrl = require('./icons/bag.svg')
const statusColors = {
  active: '#34C759',
  inactive: '#FF3B30',
}

const columns: GridColDef[] = [
  {
    flex: 3,
    field: 'businessName',
    headerName: 'Business Name',
    renderCell: row => (
      <Stack direction='row' alignItems='center' gap={1}>
        <Box component='img' width={20} height={20} src={bagIconUrl} alt='Bag' />
        {row.value}
      </Stack>
    ),
  },
  { flex: 3, field: 'dba', headerName: 'DBA' },
  { flex: 3, field: 'address', headerName: 'Address' },
  {
    flex: 3,
    field: 'licenseId',
    headerName: 'Account/License ID',
  },
  { flex: 3, field: 'entityName', headerName: 'Registered Entity Name' },
  {
    flex: 1.5,
    field: 'status',
    headerName: 'Status',
    renderCell: row => (
      <Stack direction='row' alignItems='center' gap={0}>
        {row.value ? (
          <>
            <FiberManualRecordIcon
              sx={{ color: statusColors[row.value?.toLowerCase()], circle: { r: 5 }, transform: 'translateX(-6px)' }}
            />
            {row.value}
          </>
        ) : (
          'N/A'
        )}
      </Stack>
    ),
  },
]

export default columns
