import { SxProps } from '@mui/material'
import commonStyles from '../commonStyles'

const title: SxProps = {
  fontFamily: 'Dosis',
  fontSize: '20px',
  fontWeight: 600,
  textTransform: 'uppercase',
}

const searchBySelectorButton: SxProps = {
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
}

const styles = {
  title,
  searchBySelectorButton,
  paperContainer: commonStyles.paperContainer,
  dataGrid: commonStyles.dataGrid,
}

export default styles
