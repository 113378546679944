import React, { useState } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import styles from './LeafletMap.scss'
import AddZoomControl from './ZoomControl/ZoomControl'
import AddLegendControl from './LegendControl/LegendControl'

interface Location {
  address: string
  lat?: any
  lon?: any
}

const LeafletMap = () => {
  const [locations] = useState<Location[]>([
    { address: '16 Vasil Barnov St', lat: 42.6977, lon: 23.3219 },
    { address: '72a Ilia Chavchavadze Avenue', lat: 41.7225, lon: 44.7775 },
  ])

  const marker = L.icon({
    iconUrl: require('./assets/reddot.svg'),
  })

  return (
    <MapContainer
      attributionControl={false}
      center={[50.5, 30.5]}
      zoom={3}
      zoomControl={false}
      className={styles.container}
    >
      <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
      <AddZoomControl />
      <AddLegendControl />
      {locations.map((location, index) => {
        if (!location.lat || !location.lon) return

        return (
          <Marker key={index} position={[location.lat, location.lon]} icon={marker} riseOnHover={true}>
            <Popup>{location.address}</Popup>
          </Marker>
        )
      })}
    </MapContainer>
  )
}

export default LeafletMap
