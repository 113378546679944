import React, { useEffect } from 'react'
import ReactDOMServer from 'react-dom/server'
import { useMap } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import styles from '../LeafletMap.scss'
import Box from '@mui/material/Box'
import CircleIcon from '@mui/icons-material/Circle'

const AddLegendControl = () => {
  const map = useMap()

  useEffect(() => {
    const legendDiv = L.DomUtil.create('div', '')
    legendDiv.innerHTML = ReactDOMServer.renderToString(
      <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <div className={styles.legendRow}>
          <CircleIcon fontSize='small' style={{ fontSize: '10px', color: '#2257C5' }} />
          <span>Business Addresses</span>
        </div>
        <div className={styles.legendRow}>
          <CircleIcon fontSize='small' style={{ fontSize: '10px', color: '#FF5837' }} />
          <span>Employees Location</span>
        </div>
      </Box>,
    )
    Object.assign(legendDiv.style, {
      backgroundColor: 'white',
      border: '1px solid #DAD8DF',
      borderRadius: '8px',
      padding: '16px',
      margin: '0 0 24px 24px',
    })

    const legendControl = new L.Control({ position: 'bottomleft' })
    legendControl.onAdd = () => legendDiv
    legendControl.addTo(map)

    return () => {
      map.removeControl(legendControl)
    }
  }, [map])

  return null
}

export default AddLegendControl
