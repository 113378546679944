import Box from '@mui/material/Box'
import LeafletMap from 'components/LeafletMap/LeafletMap'
import ScoreCard from 'components/ScoreCard/ScoreCard'
import React from 'react'
import TabScrolledContent from '../TabScrolledContent/TabScrolledContent'
import styles from './Overview.scss'

const Overview = () => {
  return (
    <TabScrolledContent>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', gap: '24px' }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '24px' }}>
          <ScoreCard
            title='Magnitude'
            parameters={[
              { parameter: 'Website Ranking', html: <>5,500 - 12,000</> },
              { parameter: 'Website Ranking', html: <>5,500 - 12,000</> },
              { parameter: 'Website Ranking', html: <>5,500 - 12,000</> },
              { parameter: 'Website Ranking', html: <>5,500 - 12,000</> },
            ]}
            value={50}
            escalationLabel='MEDIUM'
          />
          <ScoreCard
            title='Risk'
            parameters={[
              { parameter: 'Website Ranking', html: <>5,500 - 12,000</> },
              { parameter: 'Website Ranking', html: <>5,500 - 12,000</> },
              { parameter: 'Website Ranking', html: <>5,500 - 12,000</> },
              { parameter: 'Website Ranking', html: <>5,500 - 12,000</> },
            ]}
            value={90}
          />
        </Box>
        <Box sx={{ width: '100%', height: '100%' }}>
          <Box className={styles.locationsContainer}>
            <h3>Locations</h3>
          </Box>
          <LeafletMap />
        </Box>
      </Box>
    </TabScrolledContent>
  )
}

export default Overview
