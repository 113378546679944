import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import Stack from '@mui/material/Stack'
import Box from '@mui/system/Box'
import DescriptionCell from '../ShowMoreCell/ShowMoreCell'
import { IEmployee, IExperience, calculatePeriod, formatDate } from './utils'

const personIconUrl = require('./icons/person.svg')
// const flyIconUrl = require('./icons/fly.svg')

const hasExperiences = (item: IEmployee) => {
  return item.experiences !== undefined && item.experiences.length > 0
}

const columns: GridColDef[] = [
  {
    flex: 2,
    field: 'name',
    headerName: 'Name',
    renderCell: data => {
      const name = hasExperiences(data.row) ? data.row.experiences![0].name : data.row.name
      let icon = <></>

      if (hasExperiences(data.row)) {
        const isExecutive = data.row.experiences!.some((experience: IExperience) => experience.isExecutiveRole)
        if (isExecutive) {
          icon = <Box component='img' width={20} height={20} src={personIconUrl} alt='Executive Employee' />
        }
      }
      return (
        <Stack direction='row' alignItems='center' gap={1}>
          {name}
          {icon}
        </Stack>
      )
    },
  },
  {
    flex: 2,
    field: 'jobLocation',
    headerName: 'Location',
    renderCell: data => {
      const value = hasExperiences(data.row) ? data.row.experiences![0].jobLocation : data.row.jobLocation
      return (
        <Stack direction='row' alignItems='center' gap={1}>
          {value}
          {/* <Box component='img' width={20} height={20} src={flyIconUrl} alt='Person' /> */}
        </Stack>
      )
    },
  },
  {
    flex: 2,
    field: 'jobDepartment',
    headerName: 'Department',
    renderCell: data => {
      const value = hasExperiences(data.row) ? data.row.experiences![0].jobDepartment : data.row.jobDepartment
      return value
    },
  },
  {
    flex: 2,
    field: 'jobRole',
    headerName: 'Job Role',
    renderCell: data => {
      const value = hasExperiences(data.row) ? data.row.experiences![0].jobRole : data.row.jobRole
      return value
    },
  },
  {
    flex: 1,
    field: 'dateFrom',
    headerName: 'Start Date',
    renderCell: data => {
      const date = hasExperiences(data.row) ? data.row.experiences![0].dateFrom : data.row.dateFrom
      return formatDate(Number(date))
    },
  },
  {
    flex: 1,
    field: 'dateTo',
    headerName: 'End Date',
    renderCell: data => {
      const date = hasExperiences(data.row) ? data.row.experiences![0].dateTo : data.row.dateTo
      return formatDate(Number(date))
    },
  },
  {
    flex: 1,
    field: 'period',
    headerName: 'Period',
    renderCell: data => {
      const field = hasExperiences(data.row)
        ? calculatePeriod(Number(data.row.experiences![0].dateFrom), Number(data.row.experiences![0].dateTo))
        : calculatePeriod(Number(data.row.dateFrom), Number(data.row.dateTo))
      return field
    },
  },
  {
    flex: 3,
    field: 'jobDesc',
    headerName: 'Description',
    renderCell: data => {
      const value = hasExperiences(data.row) ? data.row.experiences![0].jobDesc : data.row.jobDesc

      return (
        <Stack direction='row' alignItems='center' gap={1}>
          <DescriptionCell description={value} />
        </Stack>
      )
    },
  },
]

export default columns
