import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { DataGrid } from '@mui/x-data-grid'
// import Select from 'components/Select/Select'
import SearchBar from '../SearchBar/SearchBar'
import Button from 'components/Button'
import Icon from 'components/Icon'
import TabScrolledContent from '../TabScrolledContent/TabScrolledContent'
import WhiteBoxWrapper from '../WhiteBoxWrapper/WhiteBoxWrapper'
import CustomPagination from '../Pagination/Pagination'
import columns from './columns'
import modularStyles from './Employees.scss'
import { useEmployeesQuery } from 'containers/VerticalPage/company-overview/EmployeesTab/useEmployeesQuery'
import { CurrentZone } from 'utils/types'
import { useSelector } from 'react-redux'
import { selectCurrentZone } from 'store/global'
import useVerticalSearchAndSort from 'hooks/useVerticalSearchAndSort'
import { Filter } from 'components/FilterDrawer/FilterDrawer.config'
import FilterDrawer from 'components/FilterDrawer/FilterDrawer'
import { useFilterOptionsQuery } from 'hooks/useFilterOptionsQuery'
import { filtersInitialState } from './filter.config'
import styles from './styles'
import { useDebounce } from 'hooks/useDebounce'

// const mockList = [
//   { id: 1, label: 'Name' },
//   { id: 2, label: 'Department' },
//   { id: 3, label: 'Location' },
//   { id: 4, label: 'Role' },
// ]

const Employees = () => {
  const currentZone: CurrentZone = useSelector(selectCurrentZone)

  const { searchValue, searchValueDebounced, setSearchValue } = useVerticalSearchAndSort()

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  })
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false)
  const [activeFilters, setActiveFilters] = useState<Filter[]>([])

  const { filterOptions } = useFilterOptionsQuery({
    verticalIndices: currentZone?.ecommerceExtendedEmployeesIndices,
    currentQueryKey: 'company-overview-filter-options-key',
    filtersInitialState,
  })
  const activeFiltersDebounced = useDebounce(activeFilters, 500)

  const {
    verticalDocumentActivity: employeesData,
    isFetching,
    total,
  } = useEmployeesQuery({
    caseId: 'ILC-990358', // Hardcode for now, we will use real data when we have index ready
    zoneActivityIndices: currentZone.ecommerceExtendedEmployeesIndices,
    pageSize: paginationModel.pageSize,
    searchValue: searchValueDebounced,
    filters: activeFiltersDebounced,
    sort: [],
    page: paginationModel.page,
  })

  useEffect(() => {
    setPaginationModel(prevPaginationModel => ({ page: 0, pageSize: prevPaginationModel.pageSize }))
  }, [searchValueDebounced, activeFiltersDebounced])

  const handleFilterButtonClick = () => {
    setIsFilterDrawerOpen(!isFilterDrawerOpen)
  }

  return (
    <TabScrolledContent>
      <WhiteBoxWrapper>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={styles.title}>Employees List</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <SearchBar
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                placeholder='Search by name, url, job role or description keyword'
              />
              {/* Comment for now until we will know how exact it should work */}
              {/* <Select
                sxButton={styles.searchBySelectorButton}
                list={mockList}
                selected={0}
                onChange={id => console.log('selected', id)}
              >
                Search by columns
              </Select> */}
            </Box>
            <Button className={modularStyles.actionsBarButton} onClick={handleFilterButtonClick}>
              <Icon size='standard' name='filterBlue' />
            </Button>
            {filterOptions && (
              <FilterDrawer
                open={isFilterDrawerOpen}
                onClose={() => setIsFilterDrawerOpen(false)}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                filtersInitialState={filtersInitialState}
                filterOptions={filterOptions}
              />
            )}
          </Box>
        </Box>
        <Paper sx={styles.paperContainer}>
          <DataGrid
            disableColumnSorting
            disableColumnMenu
            disableColumnResize
            disableColumnSelector
            disableColumnFilter
            rows={employeesData}
            columns={columns}
            sx={styles.dataGrid}
            paginationMode='server'
            slots={{
              pagination: CustomPagination,
            }}
            getRowHeight={() => 'auto'}
            onPaginationModelChange={setPaginationModel}
            rowCount={total}
            loading={isFetching}
            paginationModel={paginationModel}
          />
        </Paper>
      </WhiteBoxWrapper>
    </TabScrolledContent>
  )
}

export default Employees
