// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LeafletMap__container--Ql9m6{height:388px;border-radius:0 0 15px 15px}.LeafletMap__legendRow--_jEFv{display:flex;align-items:center;gap:8px;font-family:\"Source Sans Pro\";font-size:16px;color:#1c2842}", "",{"version":3,"sources":["webpack://./app/components/LeafletMap/LeafletMap.scss"],"names":[],"mappings":"AAAA,8BACE,YAAA,CACA,2BAAA,CAGF,8BACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,6BAAA,CACA,cAAA,CACA,aAAA","sourcesContent":[".container {\n  height: 388px;\n  border-radius: 0 0 15px 15px;\n}\n\n.legendRow {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  font-family: 'Source Sans Pro';\n  font-size: 16px;\n  color: #1c2842;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "LeafletMap__container--Ql9m6",
	"legendRow": "LeafletMap__legendRow--_jEFv"
};
export default ___CSS_LOADER_EXPORT___;
