import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

interface SocialBlockProps {
  logo: React.ReactNode
  text: string
  backgroundColor: string
  textColor: string
}

const SocialBlock = ({ logo, text, backgroundColor, textColor }: SocialBlockProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: backgroundColor,
        padding: 2,
        borderRadius: 1,
        width: '350px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {logo}
        <Typography
          variant='body1'
          color={textColor}
          sx={{
            fontFamily: 'Source Sans Pro',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '24px',
            textAlign: 'left',
          }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  )
}

export default SocialBlock
