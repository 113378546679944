import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/system/Box'
import Button from '@mui/material/Button'
import styles from './styles'

const LENGTH = 45

const DescriptionCell = ({ description }: { description: string }) => {
  const [showFullDescription, setShowFullDescription] = useState(false)

  if (!description || description.length <= LENGTH) {
    return <Typography>{description}</Typography>
  }

  return (
    <Box
      sx={{
        display: 'flex',
        ...(showFullDescription
          ? { flexDirection: 'column', alignItems: 'flex-start', gap: '8px' }
          : { alignItems: 'center', justifyContent: 'space-between', gap: '12px' }),
      }}
    >
      <Typography sx={styles.text}>
        {showFullDescription ? description : `${description.substring(0, LENGTH)}...`}
      </Typography>
      <Button onClick={() => setShowFullDescription(!showFullDescription)} sx={styles.button}>
        {showFullDescription ? 'Hide' : 'View'}
      </Button>
    </Box>
  )
}

export default DescriptionCell
